import React, { Fragment } from "react";
import { Link } from "gatsby";
import PresentationDetail from "../../../../components/hasuracon2021/homepage/PresentationDetail";
import PresenterImage from "../../../../components/hasuracon2021/images/speaker-detail-1.png";
import Seo from "../../../../components/seo";
import Layout from "../../../../components/hasuracon2021/Layout";
import {
  TwitterIcon,
  Github,
  LinkedInIcon,
} from "../../../../components/hasuracon2021/homepage/Socials";
import { MDXRenderer } from "gatsby-plugin-mdx";

const canonicalUrl = "https://hasura.io/events/hasura-con-2021/speakers/";
const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021.png",
};

const Arrow = () => (
  <svg width={65} height={68} viewBox="0 0 65 68" fill="none">
    <path
      d="M14.53 35.88L63 53.482v11.215L4.315 34 63 3.303V14.71L14.537 32.118 9.325 33.99l5.205 1.89z"
      strokeWidth={4}
    />
    <defs>
      <linearGradient
        id="linearStyle"
        x1={-6.50002}
        y1={204.001}
        x2={74.7581}
        y2={203.981}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1EB4D4" />
        <stop offset={0.46875} stopColor="#FFC267" />
        <stop offset={1} stopColor="#F47E7E" />
      </linearGradient>
    </defs>
  </svg>
);

const PresenterDetail = ({ pageContext, location }) => {
  const { presentations, presenter, previous, next, slug } = pageContext;
  const { frontmatter, body } = presenter;
  const { name, title, company, github, linkedin, twitter, image } = frontmatter;

  return (
    <Layout
      location={location}
      heroGraphic={() => (
        <img
          className="heroBanRightPattern smallSquarePattern"
          loading="lazy"
          src={PresenterImage}
          alt="Speakers Graphic"
        />
      )}
    >
      {presentations.map((presentation, index) => {
        const { childMdx } = presentation;
        const { frontmatter, body } = childMdx;
        const { presenters, ...rest } = frontmatter;

        return (
          <Fragment>
            <Seo
              title={`HasuraCon’21: ${frontmatter.title} | By ${name}, ${title}, ${company}`}
              description={frontmatter.meta_description}
              meta={ogImage}
              canonicalLink={canonicalUrl + slug + "/"}
            />
            <PresentationDetail
              key={index}
              presentation={rest}
              presenters={presenters}
              body={body}
              summary={true}
              slug={slug}
            />
          </Fragment>
        );
      })}
      <article className="speakerDetailFull">
        <section>
          <p className="hasCon21Breadcrumb speakerPage">
            <Link to="/events/hasura-con-2021">Conference</Link> /{" "}
            <Link to="/events/hasura-con-2021/speakers">Speakers</Link> /{" "}
            {presenter.frontmatter.name}
          </p>
          <header className="details">
            <h2>{name}</h2>
            <p>
              {title}, {company}
            </p>
          </header>
          <main>
            <MDXRenderer>{body}</MDXRenderer>
          </main>
          <aside>
            <div className={`speakerSocials`}>
              <Link className="inlineBoxCTA" to="/events/hasura-con-2021/recordings/">
                View Recordings →
              </Link>
              {twitter && (
                <a href={`https://twitter.com/${twitter}`}>
                  <TwitterIcon />
                </a>
              )}
              {github && (
                <a href={`https://github.com/${github}`}>
                  <Github />
                </a>
              )}

              {linkedin && (
                <a href={linkedin}>
                  <LinkedInIcon />
                </a>
              )}
            </div>
          </aside>
        </section>
        <div className="profile">
          <div className="profileImage">
            <img src={`${image.childImageSharp.original.src}`} alt={name} />
          </div>
        </div>
      </article>

      <nav className="hasCon21Pagination">
        <Link to={`/events/hasura-con-2021/speakers/${previous.presenter_slug}/`}>
          <div className="linkBox">
            <div className="navArrowBox">
              <Arrow />
            </div>
            <div className="navTitleBox">
              <h2>{previous.name}</h2>
            </div>
          </div>
        </Link>
        <Link to={`/events/hasura-con-2021/speakers/${next.presenter_slug}/`}>
          <div className="linkBox">
            <div className="navTitleBox">
              <h2>{next.name}</h2>
            </div>
            <div className="navArrowBox right">
              <Arrow />
            </div>
          </div>
        </Link>
      </nav>
    </Layout>
  );
};

export default PresenterDetail;
